<template>
  <el-dialog v-model="dialogVisible" title="包厢类型" width="800px" :before-close="handleClose">
    <div class="package-type-header">
      <el-button type="primary" @click="handleAdd">添加类型</el-button>
    </div>

    <el-table :data="tableData" border style="width: 100%">
      <el-table-column align="center" prop="name" label="类型名称" />
      <el-table-column align="center" prop="parentName" label="所属类型" />
      <el-table-column align="center" prop="remarks" label="备注" />
      <el-table-column align="center" label="启用状态" width="100">
        <template #default="scope">
          <span :style="{ color: scope.row.isEnable == 1 ? '#67C23A' : '#909399' }" style="text-align: center;">
            {{ scope.row.isEnable == 1 ? '启用' : '停用' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="150" fixed="right">
        <template #default="scope">
          <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="text" style="color: red" @click="handleDelete(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 新增/编辑弹窗 -->
    <PackageTypeForm
      v-if="formVisible"
      v-model="formVisible"
      :form-data="currentForm"
      @success="handleFormSuccess"
      :store-code="storeCode"
    />
  </el-dialog>
</template>

<script>
import { ref, defineComponent, watch, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import PackageTypeForm from './PackageTypeForm.vue'
import API from '../../../service/api'

export default defineComponent({
  name: 'PackageTypeDialog',
  components: {
    PackageTypeForm,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    storeCode: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    // 弹窗显示控制
    const dialogVisible = ref(props.modelValue)
    const formVisible = ref(false)
    const currentForm = ref(null)

    // 表格数据
    const tableData = ref([])

    // 获取包厢类型
    const getBoxTypeList = async () => {
      const res = await API.getBoxTypeList({ storeCode: props.storeCode })
      if (res.code == 200) {
        tableData.value = [...res.data]
      }
    }

    // 监听弹窗显示状态
    watch(
      () => props.modelValue,
      val => {
        dialogVisible.value = val
        if (val) {
          getBoxTypeList()
        }
      },
    )

    watch(
      () => dialogVisible.value,
      val => {
        emit('update:modelValue', val)
      },
    )

    // 关闭弹窗
    const handleClose = () => {
      dialogVisible.value = false
    }

    // 添加类型
    const handleAdd = () => {
      currentForm.value = {
        name: '',
        parentType: '',
        remark: '',
        status: true,
      }
      formVisible.value = true
    }

    // 编辑类型
    const handleEdit = row => {
      currentForm.value = { ...row }
      formVisible.value = true
    }

    // 删除类型
    const handleDelete = row => {
      ElMessageBox.confirm('确定要删除该包厢类型吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const res = await API.deleteBoxType({ code: row.code, storeCode: props.storeCode })
          if (res.code == 200) {
            getBoxTypeList()
            ElMessage.success('删除成功')
          } else {
            ElMessage.error(res.msg)
          }
        })
        .catch(() => {})
    }

    // 表单提交成功
    const handleFormSuccess = () => {
      getBoxTypeList()
      formVisible.value = false
    }

    return {
      dialogVisible,
      formVisible,
      currentForm,
      tableData,
      handleClose,
      handleAdd,
      handleEdit,
      handleDelete,
      handleFormSuccess,
    }
  },
})
</script>

<style lang="scss" scoped>
.package-type-header {
  margin-bottom: 20px;
}
</style>
